<template>
  <div class="appointments-and-reviews border-top">
    <component :is="selectedComponent"  @changeNextForm='changeNextForm' @chooseAppointment="chooseAppointment" :userInfo="userInfo" @resetAppointment="resetAppointment" :allDays="allDays" :workTimes="workTimes" :data="appointment"></component>
    <b-card class="mt-5 mb-2 px-2 iq-border-radius-20">
      <template v-slot:header>
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="main-title-details py-1 text-primary font-weight-bold">{{ $t('marketplace.clientsComments') }}</h3>
          <b-button
              class="d-flex justify-content-between align-items-center bg-transparent border-0 see-more"
          >
            <span class="main-see-more-detail text-primary ml-1">{{ $t('marketplace.seeAll') }}</span>
            <i class="las la-caret-left text-primary font-size-26"></i>
            <!--          <i class="las la-shopping-cart ml-0 mr-2 font-size-26 mr-5"></i>-->
          </b-button>
        </div>
      </template>
      <b-row class="justify-content-between">
        <b-col md="3">
          <div class="d-flex flex-column justify-content-start">
            <div class="rate-header d-flex flex-column justify-content-center align-items-center mb-4">
              <h1 class="font-weight-bold text-primary mt-3 mb-3">4/5</h1>
              <div class="star-icons star-product d-flex justify-content-center align-items-center mb-4">
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
              </div>
              <h3 class="text-primary">107</h3>
              <p class="font-size-24 text-primary mb-3">{{ $t('marketplace.validProductRate') }}</p>
            </div>
            <div class="rate-progress d-flex flex-column justify-content-start mb-4">
              <div class="rate-details d-flex justify-content-between align-items-center w-100" v-for="(item , key) in rateDetails" :key="key">
                <p class="text-secondary font-size-18 m-0 p-0">{{item.id}}</p>
                <img
                    class="inline-flex star-icon-rate"
                    :src="item.img"
                />
                <p class="text-secondary font-size-20 m-0 p-0">({{item.num}})</p>
                <div class="iq-progress-bar-linear d-inline-block w-50">
                  <div class="iq-progress-bar">
                    <span :data-value="item.value" class="bg-warning" style="transition: width 2s ease 0s; width: 55%;"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8">
          <div class="d-flex flex-column justify-content-start">
            <p class="main-users-comment-order text-primary mb-3">{{ $t('marketplace.userProductComments') }}</p>
            <div class="rate-header d-flex flex-column justify-content-start p-3 mb-3" v-for="(comment , key) in clientComment" :key="key">
              <div class="star-icons star-product-comment d-flex  align-items-center mb-3">
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
                <img
                    class="inline-flex mr-1"
                    :src="require('@/assets/images/ibbil/star.svg')"
                />
              </div>
              <h4 class="text-primary font-weight-bold mb-2">{{comment.commentName}}</h4>
              <p class="text-muted font-size-16 mb-3">{{comment.commentDetails}}</p>
              <div class="d-flex justify-content-between align-items-center flex-md-row flex-column mb-0">
                <div class="comment-details m-0 p-0">
                  <p>{{ $t('marketplace.commentedAt') }} <span>{{comment.commentDay}} </span><span>{{comment.commentDate}}</span><span>{{comment.commentYear}}</span>بواسطة <span class="text-primary font-weight-bold mr-2"> {{comment.userComment}} </span></p>
                </div>
                <div class="d-flex align-items-center flex-row-reverse gap_1">
                  <img :src="require('@/assets/images/ibbil/permissions.svg')" alt="permissions" />
                  <p class="text-success font-size-16  p-0 m-0">{{ $t('marketplace.validProductPayment') }}</p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import appointmentChooseDay from './appointmentChooseDay'
import appointmentChooseTime from './appointmentChooseTime'
import moment from 'moment'
export default {
  components: {
    appointmentChooseDay,
    appointmentChooseTime
  },
  props: ['workTimes', 'userInfo'],
  data () {
    return {
      selectedComponent: 'appointmentChooseDay',
      appointment: {},
      rateDetails: [
        {
          id: 5,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 76,
          value: 75
        },
        {
          id: 4,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 22,
          value: 55
        },
        {
          id: 3,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 12,
          value: 38
        },
        {
          id: 2,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 18,
          value: 45
        },
        {
          id: 1,
          img: require('@/assets/images/ibbil/star.svg'),
          num: 7,
          value: 13
        }
      ],
      clientComment: [
        {
          id: 0,
          commentName: 'منتج جميل',
          commentDetails: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.',
          commentDay: 7,
          commentDate: 'مارس',
          commentYear: 2021,
          userComment: 'Nawwaf'
        },
        {
          id: 1,
          commentName: 'منتج رائع',
          commentDetails: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.',
          commentDay: 22,
          commentDate: 'أغسطس',
          commentYear: 2022,
          userComment: 'Ahmed'
        }
      ],
      allDays: []
    }
  },
  methods: {
    chooseAppointment (data, component) {
      this.appointment = { ...this.appointment, ...data }
      this.selectedComponent = component
    },
    resetAppointment () {
      this.selectedComponent = 'appointmentChooseDay'
      this.appointment = {}
    },
    dateRange (startDate, endDate, steps = 1) {
      const dateArray = []
      const currentDate = new Date(startDate)
      while (currentDate <= new Date(endDate)) {
        dateArray.push(moment(new Date(currentDate)).format('L'))
        currentDate.setUTCDate(currentDate.getUTCDate() + steps)
      }
      return dateArray
    },
    getTowWeekFromNow () {
      var today = new Date()
      var after30Day = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30)
      this.allDays = this.dateRange(new Date(), after30Day)
    },
    changeNextForm (time) {
      this.$emit('changeForm', time)
    }
  },
  created () {
    this.getTowWeekFromNow()
  }
}
</script>

<style>
.rate-header {
  width: 100%;
  background: #FBFBFB;
  border-radius: 8px;
}
</style>
