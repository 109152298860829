<template>
  <b-container class="mt-5">
    <div id="pay-popup" v-show="pay_popup" title="دفع" size="lg">
      <b-card>
        <div id="embed-target1"></div>
      </b-card>
    </div>
    <b-row>
      <b-col md="2" class="d-flex justify-content-end">
        <p
          @click="reverseCompnent"
          class="text-warning font-size-16 font-weight-bold cursor-pointer"
          v-if="
            selectedComponent != 'serviceType' && selectedComponent != 'final'
          "
        >
          <i class="las la-angle-right font-size-16"></i>
          {{ $t("endUser.back") }}
        </p>
      </b-col>
      <b-col md="8">
        <div class="stepper d-flex justify-content-between mb-2">
          <div
            class="stepper-item position-relative"
            v-for="(status, index) in allComponent"
            :key="index"
            :class="{
              'stepper-item--completed':
                index <=
                allComponent.findIndex(
                  (component) => component.value === selectedComponent
                ),
            }"
          >
            <div
              class="stepper-item__step position-relative"
              :class="{ 'ml-3': index != 0 }"
            >
              <div class="stepper-item__circle">
                <span v-if="index != 0">{{ index }}</span>
              </div>
              <div class="stepper-item__line"></div>
            </div>
            <div
              class="stepper-item__status text-uppercase"
              v-if="status.value != 'final'"
            >
              {{ status.name }}
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
    <b-row class="justify-content-center mt-4">
      <b-col md="12">
        <transition
          name="slide-up"
          appear
          mode="out-in"
          :duration="300"
          class="w-100"
        >
          <component
            :is="selectedComponent"
            @changeForm="changeComponent"
            :info="veterinarianData"
            @requestToDoctor="pay"
            @payWithWallet="payWithWallet"
          ></component>
        </transition>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
/*eslint-disable*/
import serviceType from "../components/veterinarySteps/serviceType";
import doctorChoice from "../components/veterinarySteps/doctorChoice";
import apointmentDetermine from "../components/veterinarySteps/apointmentDetermine";
import appointmentConfirmation from "../components/veterinarySteps/appointmentConfirmation";
import final from "../components/veterinarySteps/final";
import otherStep from "../components/services/otherStep";
import veterinarianServices from "../services/veterinarian";
import { core } from "@/config/pluginInit";
export default {
  components: {
    serviceType,
    doctorChoice,
    apointmentDetermine,
    appointmentConfirmation,
    final,
    otherStep,
  },
  data() {
    return {
      pay_popup: false,
      // allComponent: [
      //   { value: 'serviceType', name: this.$t('veterinary.serviceType') },
      //   { value: 'doctorChoice', name: this.$t('veterinary.doctorChoice') },
      //   { value: 'apointmentDetermine', name: this.$t('veterinary.setSpecificTime') },
      //   { value: 'appointmentConfirmation', name: this.$t('veterinary.confirmReservation') },
      //   { value: 'final', name: 'final' }
      // ],
      selectedComponent: "serviceType",
      veterinarianData: "",
    };
  },
  computed: {
    allComponent() {
      return [
        { value: "serviceType", name: this.$t("veterinary.serviceType") },
        { value: "doctorChoice", name: this.$t("veterinary.doctorChoice") },
        {
          value: "apointmentDetermine",
          name: this.$t("veterinary.setSpecificTime"),
        },
        {
          value: "appointmentConfirmation",
          name: this.$t("veterinary.confirmReservation"),
        },
        { value: "final", name: "final" },
      ];
    },
  },
  methods: {
    pay() {
      console.log(this.veterinarianData);
      const obj = {
        doctor_id: this.veterinarianData.id,
        time: this.veterinarianData.time,
        price: this.veterinarianData.prices.clinic_examine,
        payment_method_id: "1",
        currency: "SAR",
      };
      veterinarianServices.reservation(obj).then((res) => {
        this.pay_popup = true;
        window.Checkout.configure({
          session: {
            id: res.data.payment.session.id,
          },
          interaction: {
            cancelUrl: "https://www.google.com",
            merchant: {
              name: res.data.payment.merchant,
              address: {
                line1: "200 Sample St",
                line2: "1234 Example Town",
              },
            },
          },
          order: res.data.payment.order,
        });
        // window.Checkout.showPaymentPage()
        window.Checkout.showEmbeddedPage("#embed-target1");
      });
    },
    changeComponent(data) {
      this.veterinarianData = { ...this.veterinarianData, ...data };
      const indexStepper = this.allComponent.findIndex(
        (stepper) => stepper.value === this.selectedComponent
      );
      this.selectedComponent = this.allComponent[indexStepper + 1].value;
      // if (parseInt(data.isSelected.value) === 4) {
      //   this.selectedComponent = 'otherStep'
      // } else {
      //   this.selectedComponent = this.allComponent[indexStepper + 1].value
      // }
    },
    reverseCompnent() {
      const indexStepper = this.allComponent.findIndex(
        (stepper) => stepper.value === this.selectedComponent
      );
      this.selectedComponent = this.allComponent[indexStepper - 1].value;
      // if (this.selectedComponent === 'otherStep') {
      //   this.selectedComponent = this.allComponent[0].value
      // } else {
      // }
    },
    payWithWallet(data) {
      let price = "";
      let serviceType = "";
      if (this.veterinarianData.isSelected.id === 1) {
        price = this.veterinarianData.prices.clinic_examine;
        serviceType = 'clinic_examine';
      } else if (this.veterinarianData.isSelected.id === 2) {
        price = this.veterinarianData.prices.outdoor_examine;
        serviceType = 'outdoor_examine';
      } else {
        price = this.veterinarianData.prices.consulting;
        serviceType = 'consulting';
      }
      localStorage.setItem("price", price);
      const obj = {
        doctor_id: this.veterinarianData.id,
        time: this.veterinarianData.time,
        price: price,
        payment_method_id: "2",
        currency: "SAR",
        service_type: serviceType,
        ...data,
      };
      veterinarianServices.reservation(obj).then((res) => {
        core.showSnackbar("success", res.data.message);
        this.selectedComponent = "final";
      });
    },
  },
  mounted() {
    core.index();
  },
};
</script>
<style lang="scss">
.stepper-item__status {
  position: absolute;
  right: 40%;
  top: -14px;
}
.stepper {
  .cancelled-order {
    background: #f00;
    padding: 4px 8px;
    border-radius: 4px;
    span {
      color: #fff;
      font-weight: 600;
    }
  }
  .stepper-item {
    position: relative;
    .stepper-item__circle {
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--iq-secondary);
      color: #fff;
      z-index: 2;
      font-weight: bold;
      font-size: 12px;
      line-height: 0;
    }
    .stepper-item__status {
      color: var(--iq-secondary);
    }
    &:not(:last-child) {
      flex-grow: 1;
      .stepper-item__line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: calc(100% + 15px);
        height: 4px;
        background: var(--iq-secondary);
      }
    }
    @media (max-width: 991px) {
      .stepper-item__circle {
        width: 20px;
        height: 20px;
        i {
          font-size: 0.8rem;
        }
      }
      .stepper-item__status {
        font-size: 12px;
      }
    }
    &.stepper-item--completed {
      .stepper-item__circle {
        background: var(--iq-primary);
        box-shadow: inset 0px 3px 6px #00000096;
      }
      .stepper-item__status {
        font-weight: bold;
        color: var(--iq-primary);
      }
      .stepper-item__line {
        background: var(--iq-primary);
      }
    }
  }
}
[dir="rtl"] .stepper-item {
  .stepper-item__line {
    right: 0px;
    left: auto;
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.6s ease-in-out;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
</style>
