<template>
  <div>
    <h3 class="text-center title">
      {{ $t("veterinary.chooseReservationDate") }}
    </h3>
    <div class="iq-card appointments-time">
      <div class="iq-card-body p-0 d-flex">
        <div
          class="info d-flex flex-column justify-content-center align-items-center position-relative"
        >
          <span class="day font-size-25 mb-1">{{
            days[new Date(data.date).getDay()]
          }}</span>
          <span class="date font-size-20"
            >{{ new Date(data.date).getDate() }}
            {{ months[new Date(data.date).getMonth()] }}</span
          >
          <b-button
            variant="warning"
            class="bg-transparent border-0 p-0 mt-auto position-absolute edit-btn"
            @click="resetAppointment"
          >
            {{ $t("veterinary.modifyDay") }}</b-button
          >
        </div>
        <div class="flex-fill appointment-rows">
          <b-row cols="6">
            <b-col
              v-for="(item, index) in appointments"
              :key="index"
              class="mb-4"
            >
              <b-button
                class="bg-transparent appointment-btn w-100 p-0"
                @click="
                  goToNextStep({
                    time: { time: item, date: data.date, day: data.dayEn },
                  })
                "
                >{{ conver(item) }}</b-button
              >
            </b-col>
          </b-row>

          <!-- Alert when no available slots for 'appointment' method -->
          <p
            class="alert alert-danger"
            v-if="userInfo.examine_method === 'appointment' && showNoSlotsAlert"
          >
          لا توجد مواعيد متاحة
          </p>

          <!-- Alert when user can't reserve for 'first-come-first-served' -->
          <p class="alert alert-danger" v-if="showReservationAlert">
            لقد تم الوصول إلى الحد الأقصى للحجوزات في هذا اليوم. نرجو منك
            المحاولة في يوم آخر
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from "moment";
import veterinarianServices from "../../services/veterinarian";

export default {
  props: ["data", "userInfo"],
  data() {
    return {
      months: [
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "مايو",
        "يونيو",
        "يوليه",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
      days: [
        "الأحد",
        "الإتنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      appointments: [],
      canReserve: null, // Initialize as null for initial state
      showAlert: false,
      showNoSlotsAlert: false, // New state to control "no slots" alert
      availableSlots: [],
    };
  },
  computed: {
    showReservationAlert() {
      return (
        this.userInfo.examine_method === "first-come-first-served" &&
        this.canReserve !== null &&
        !this.canReserve
      );
    },
  },
  methods: {
    resetAppointment() {
      this.$emit("resetAppointment");
    },
    convertTime12To24(time) {
      if (!time || typeof time !== "string") {
        console.error("Invalid time format:", time);
        return null; // Or some default value like '00:00'
      }

      let hours, minutes;

      if (time.includes("AM") || time.includes("PM")) {
        // Handle 12-hour format with AM/PM
        hours = Number(time.match(/^(\d+)/)[1]);
        minutes = Number(time.match(/:(\d+)/)[1]);
        const AMPM = time.match(/\s(.*)$/)[1];
        if (AMPM === "PM" && hours < 12) hours += 12;
        if (AMPM === "AM" && hours === 12) hours = 0;
      } else {
        // Handle 24-hour format
        hours = Number(time.split(":")[0]);
        minutes = Number(time.split(":")[1]);
      }

      const sHours = hours < 10 ? "0" + hours : hours.toString();
      const sMinutes = minutes < 10 ? "0" + minutes : minutes.toString();

      return sHours + ":" + sMinutes;
    },
    convertH2M(timeInHour) {
      var timeParts = timeInHour.split(":");
      return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    },
    goToNextStep(time) {
      this.$emit("changeNextForm", time);
    },
    chooseAppointment(from, to, reservedTimes) {
      const periods = this.data.periods || []; // Ensure periods are received

      console.log("Received periods:", periods); // Debugging line

      if (
        this.userInfo.examine_method === "appointment" &&
        periods.length > 0
      ) {
        this.availableSlots = [];

        periods.forEach((period) => {
          var x = {
            nextSlot: this.convertH2M(this.userInfo.examination_duration),
            startTime: this.convertTime12To24(period.from),
            endTime: this.convertTime12To24(period.to),
          };
          var slotTime = moment(x.startTime, "HH:mm");
          var endTime = moment(x.endTime, "HH:mm");

          while (slotTime < endTime) {
            let slotFormatted = slotTime.format("HH:mm");
            if (!reservedTimes.includes(slotFormatted)) {
              this.availableSlots.push(slotFormatted);
            }
            slotTime = slotTime.add(x.nextSlot, "minutes");
          }
        });

        if (this.availableSlots.length === 0) {
          this.showNoSlotsAlert = true;
        } else {
          this.appointments = this.availableSlots;
          this.showNoSlotsAlert = false;
        }
      } else if (this.userInfo.examine_method === "first-come-first-served") {
        if (this.canReserve == true) {
          this.goToNextStep({
            time: { time: from, date: this.data.date, day: this.data.dayEn },
          });
        } else {
          console.log("Sorry, you cannot reserve at this time.");
          this.showAlert = true;
        }
      }
    },

    getReservedTimes() {
      veterinarianServices
        .getAllReservedTime(this.userInfo.id, this.data.dayEn, this.data.date)
        .then((res) => {
          if (res.data && res.data.data) {
            this.chooseAppointment(this.data.from, this.data.to, res.data.data);
          } else {
            console.error("No data found for the selected doctor.");
          }
        })
        .catch((err) => {
          console.error("Error fetching reserved times:", err);
        });
    },
    getAllReservationsCount() {
      return veterinarianServices
        .getReservationsCount(this.userInfo.id, this.data.dayEn, this.data.date)
        .then((res) => {
          console.log("Reservations count:", res.data.canReserve);
          this.canReserve = res.data.canReserve;
        })
        .catch((err) => {
          console.error("Error fetching reservations count:", err);
        });
    },

    conver(time) {
      return moment(time, "HH:mm").format("LT");
    },
  },
  created() {
    // Use async/await for asynchronous operations
    this.getReservedTimes();
    this.getAllReservationsCount().then(() => {
      console.log("canReserve updated:", this.canReserve);
      if (
        this.canReserve !== null &&
        this.userInfo.examine_method === "first-come-first-served"
      ) {
        this.chooseAppointment(this.data.from, this.data.to, []);
      }
    });
  },
};
</script>
