<template>
<b-container>
  <div class="doctor doctor-head">
    <form>
      <b-row class="justify-content-center">
        <b-col lg="10">
          <b-row class="align-items-center">
            <b-col lg="3">
              <div class="d-flex align-items-center">
                <label class="m-0"><span class="ml-2 font-size-18 text-muted">{{ $t('veterinary.city') }}</span></label>
                <main-select
                    :reduce="city => city.id"
                    :options=allCities
                    v-model="city"
                    label="name"
                    class="flex-grow-1 mb-0 w-100 filter-select"
                    :placeholder="$t('main.city')"
                    dir="rtl"/>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="d-flex align-items-center">
                <label class="m-0"><span class="ml-2 font-size-18 ">{{ $t('veterinary.sort') }}</span></label>
                <main-select
                    :options="sort"
                    class="flex-grow-1 mb-0 w-100 filter-select"
                    :placeholder="$t('main.sort')"
                    label="name"
                    dir="rtl"
                    :reduce="data => data.value"
                    @selecting="selectSortData"
                />
              </div>
            </b-col>
            <b-col lg="5">
              <div class="d-flex align-items-center">
                <label class="m-0"><span class="ml-2 font-size-18 text-muted">{{ $t('main.search') }}</span></label>
                <div
                  class="filter-input flex-grow-1 bg-white ps-3 d-flex align-items-center px-3 rounded flex-grow-1 "
                >
                  <i class="las la-search text-gray font-size-22"></i>
                  <b-input
                    :placeholder="$t('veterinary.searchByName')"
                    class="bg-transparent border-0 flex-grow-1 text-muted "
                    v-model="filter.search"
                  />
                </div>
              </div>
            </b-col>
            <b-col auto>
              <b-button class="submit-btn bg-transparent border-0 rounded-0 p-0 font-size-18">{{ $t('veterinary.filter') }}</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </div>
  <b-row class="mt-5 mb-5">
    <b-col v-for="(doctor, index) in doctors" :key="index" sm="12" class="mb-4">
      <doctor-card :data="doctor" :infoSelected="info" :appointment="false" @changeForm="changeMainForm"/>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
/*eslint-disable*/ 
import doctorCard from './doctorCard'
import veterinarianServices from '../../services/veterinarian'
import citiesAndCountriesMixin from '@/mixins/countriesAndCities'

export default {
  mixins: [citiesAndCountriesMixin],
  components: {
    doctorCard
  },
  props: ['info'],
  data () {
    return {
      city: '',
      selectSort: 'DESC',
      sort: [
        {
          name: 'الكل',
          value: ''
        },
        {
          name: 'من الأحدث للأقدم',
          value: 'ASC'
        },
        {
          name: 'من الأقدم للأحدث',
          value: 'DESC'
        }
      ],
      filter: {
        search: ''
      },
      doctors: []
    }
  },
  watch: {
    'info.isSelected.id': {
      handler(newValue) {
        this.getAllDoctors();
      },
      immediate: true
    }
  },
  methods: {
    selectSortData (selectSort) {
      this.selectSort = selectSort.value
    },
    changeMainForm (data) {
      this.$emit('changeForm', data)
    },
    getAllDoctors () {
      const selectedServiceId = this.info.isSelected.id;
      const query = selectedServiceId === 1 ? 'allow_clinic_examine=1' : selectedServiceId === 2 ? 'allow_outdoor_examine=1' : selectedServiceId === 3 ? 'allow_consulting=1' : '';
      veterinarianServices.getAllDoctor(query).then(res => {
        this.doctors = res.data.data;
      });
    }
  },
  created () {
    this.getAllDoctors()
  }
}
</script>

