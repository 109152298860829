<template>
  <div>
    <div class="fees-company-container">
      <b-alert variant="success" show class="d-flex align-items-center iq-border-radius-10 p-4 pr-5 pl-5">
        <h3 class="text-cyan font-weight-bold font-size-25">{{ $t('veterinary.bookingConfirmed') }}</h3>
      </b-alert>
      <div class="mb-5">
        <b-card :styles="{'--cols': 3, '--rows': 2}">
              <h3 class="font-size-25 doctor-card-header-title">{{ $t('veterinary.reservationData') }}</h3>
          <div class="iq-card doctor-card m-0">
            <slot name="header"></slot>
            <div class="iq-card-body d-flex justify-content-between align-items-center flex-wrap">
              <div class="doctor-card-main-info d-flex align-items-center flex-fill gap_1">
                <div
                    class="rounded-circle profile-doctor" :style="{
                    'background-image': 'url(' + info.personal_picture + ')',
                         'background-size' : 'cover',
                                'background-position': 'center',
                                'background-repeat': 'no-repeat'
                  }">
                </div>
                <div class="main-info">
                  <h6 class="main-info-subtitle font-size-16">{{ $t('veterinary.doctor') }}</h6>
                  <h6 class="main-info-title font-size-20">{{info.full_name}}</h6>
                  <h6 class="main-info-subtitle mb-2 font-size-16">{{info.isSelected.title}}</h6>
                   {{info.clinic_address}}
                  <ul class="list-unstyled m-0 d-flex align-items-center gap-1 p-0">
                    <li class="font-size-16">({{5}})</li>
                    <li v-for="item in [1, 2, 3, 4, 5]" :key="item"><i class="las la-star icon text-warning" :class="{active : item > 2}"></i></li>
                  </ul>
                </div>
              </div>
<!--              <ul class="list-unstyled m-0 p-0 feats" v-if="!noFeats">-->
<!--                <li v-for="(feat, index) in features" :key="index" class="d-flex align-items-center font-size-16 feat">-->
<!--                  <span class="icon"><i class="las la-check"></i></span>-->
<!--                  <span class="text">{{feat}}</span>-->
<!--                </li>-->
<!--              </ul>-->
              <div class="flex-fill doctor-card-info">
                <div class="mb-3">
                  <h6 class="main-info-subtitle font-size-16">{{ $t('veterinary.appointment') }}</h6>
                  <h6 class="main-info-title font-size-20">
                    {{days[new Date(info.time.date).getDay()]}}
                    -
                    {{new Date(info.time.date).getDate()}} {{months[new Date(info.time.date).getMonth()]}}
                  </h6>
                </div>
                <div class="mb-3">
                  <h6 class="main-info-subtitle font-size-16">{{ $t('auth.address') }}</h6>
                  <h6 class="main-info-title font-size-20">{{info.clinic_address}}</h6>
                </div>
                <div class="book-price">
                  <h6 class="main-info-subtitle font-size-16">سعر الحجز</h6>
                  <h6 class="main-info-title font-size-25">{{price}} ريال سعودى</h6>
              </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
// import doctorCard from './doctorCard'

export default {
  props: ['info'],
  components: {
    // doctorCard
  },
  data () {
    return {
      months: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليه', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
      days: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
      doctorData: {
        id: 1,
        img: require('@/assets/images/ibbil/veterinarian/user1.svg'),
        name: 'محمود سيد علاء',
        position: 'استشارى بيطرى',
        university: 'جامعة الملك فهد',
        rate: '843',
        features: [
          'صالة انتظار مرحة',
          'سرير للمرافق',
          'صالة انتظار مرحة'
        ],
        address: 'الرياض - شارع المدارس',
        price: '500',
        appointment: {
          date: '10-25-2022',
          time: '06:30 ص'
        },
        userName: 'على حسين محمد',
        phone: '4857329075236'
      },
      price: localStorage.getItem('price')
    }
  }
}
</script>
