var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.workTimes[new Date(_vm.data).getDay() === 6 ? 0 : new Date(_vm.data).getDay() + 1]
      .is_active
  )?_c('swiperSlide',[_c('swiperSlide',{staticClass:"appointment-card"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center appointment-card-header",class:new Date(_vm.data).getDate() > new Date().getDate() + 1
          ? 'justify-content-between'
          : 'justify-content-center'},[(new Date(_vm.data).getDate() === new Date().getDate())?[_c('span',{staticClass:"day"},[_vm._v(_vm._s(_vm.$t("veterinary.today")))])]:(new Date(_vm.data).getDate() === new Date().getDate() + 1)?[_c('span',{staticClass:"day"},[_vm._v(_vm._s(_vm.$t("veterinary.tomorrow")))])]:[_c('span',{staticClass:"day ml-2"},[_vm._v(_vm._s(_vm.days[new Date(_vm.data).getDay()]))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(new Date(_vm.data).getDate())+" "+_vm._s(_vm.months[new Date(_vm.data).getMonth()]))])]],2),_c('div',{staticClass:"appointment-card-body text-center d-flex justify-content-between"},_vm._l((_vm.workTimes[
          new Date(_vm.data).getDay() === 6 ? 0 : new Date(_vm.data).getDay() + 1
        ].working_periods),function(period,index){return _c('section',{key:index},[_c('h6',{staticClass:"d-flex gap_1 justify-content-center align-items-center mb-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("veterinary.from")))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(period.from))])]),_c('h6',{staticClass:"d-flex gap_1 justify-content-center align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("veterinary.to")))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(period.to))])])])}),0),_c('div',{staticClass:"appointment-card-footer"},[_c('b-button',{staticClass:"app-btn p-0",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.chooseAppointment()}}},[_vm._v(_vm._s(_vm.$t("veterinary.book")))])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }