import mainServices from '@/services/main'
export default {
  data () {
    return {
      allCities: [],
      allCountries: [],
      // insuranceCompanies: [],
      allStatus: ['سارية', 'مغلقة']

    }
  },
  methods: {
    getAllCountries () {
      mainServices.getAllCities().then(res => {
        this.allCities = [{ name: 'الكل', id: '' }, ...res.data.data]
      })
    },
    getAllCities () {
      mainServices.getAllContries().then(res => {
        this.allCountries = [{ name: 'الكل', id: '' }, ...res.data.data]
      })
    }
    // getCompanies () {
    //   mainServices.getInsuranceCompany().then(res => {
    //     this.insuranceCompanies = [{ name: 'الكل', id: '' }, ...res.data.data]
    //   })
    // }
  },
  created () {
    this.getAllCountries()
    this.getAllCities()
  }
}
