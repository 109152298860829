<template>
  <b-container>
    <doctor-card
      :styles="{ '--cols': 2, '--rows': 2 }"
      :data="info"
      :noBtn="true"
      :noFeats="true"
      :horizontal="true"
    >
      <template v-slot:header>
        <div class="doctor-card-header">
          <h3 class="font-size-25 border-bottom doctor-card-header-title">
            {{ $t("veterinary.reservationData") }}
          </h3>
        </div>
      </template>
      <template v-slot:content>
        <b-row class="justify-content-center">
          <b-col lg="9">
            <b-form
              class="position-relative form-top p-4"
              @submit.prevent="handleSubmit(addNewPayment)"
            >
              <b-row>
                <b-col md="6">
                  <h6 class="main-info-title font-size-16">
                    {{ $t("auth.userName") }}
                  </h6>
                  <h6 class="main-info-title font-size-20">
                    {{ userInfo.fullName }}
                  </h6>
                </b-col>
                <b-col md="6">
                  <h6 class="main-info-title font-size-16">
                    {{ $t("auth.phoneNumber") }}
                  </h6>
                  <h6 class="main-info-title font-size-20">
                    {{ userInfo.phone }}
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <input-form
                    name="numOfCamel"
                    :placeholder="$t('veterinary.NumberOfCamels')"
                    :label="$t('veterinary.NumberOfCamels')"
                    v-model.number="number_of_camels"
                  />
                </b-col>
                <b-col md="6">
                  <input-form
                    v-if="info.isSelected.id == 2"
                    name="fullName"
                    :placeholder="$t('veterinary.address')"
                    :label="$t('veterinary.address')"
                    v-model="address"
                  />
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </template>
    </doctor-card>
    <div class="mt-5">
      <payment-component
        @payWithMaster="payWithMaster"
        @payWithWallet="payWithWallet"
      />
    </div>
  </b-container>
</template>

<script>
/*eslint-disable*/
import doctorCard from "./doctorCard";
import paymentComponent from "@/components/payment";
export default {
  components: {
    doctorCard,
    paymentComponent,
  },
  props: ["info"],
  data() {
    return {
      address: "",
      number_of_camels: "",
      userInfo: {
        fullName: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.name
          : "",
        phone: localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).user.phone
          : "",
      },
    };
  },
  methods: {
    payWithMaster() {
      this.$emit("requestToDoctor");
    },
    payWithWallet(data) {
      if (this.info.isSelected.id == 2) {
        this.$emit("payWithWallet", {
          ...data,
          address: this.address,
          number_of_camels: this.number_of_camels,
        });
      } else {
        this.$emit("payWithWallet", {
          ...data,
          number_of_camels: this.number_of_camels,
        });
      }
    },
  },
};
</script>